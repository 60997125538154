<template>
  <div v-if="!error">Athererize endpoint</div>
  <div v-else>{{ error }}</div>
</template>
<script>
import get from 'lodash/get';
// import {
//   CognitoUserPool
//   // Client,
//   // ClientMetadata
// } from 'amazon-cognito-identity-js';
export default {
  name: "Autherize",
  props: {},
  data() {
    return {
      error: ''
    }
  },
  created() {
    this.getAuthparameters()
  },
  methods: {
    isRedirectUriValid(redirectUrl) {
      if(redirectUrl) {
        return true
      } else {
        return false
      }
      
    },
    isRequestValid(params) {
      if (!get(params, 'client_id', '')){
        return false
      }
      const redirectUrl = get(params, 'redirect_uri', '')
      if (!this.isRedirectUriValid(redirectUrl)){
        return false
      }
      return true
    },
    async getAuthparameters() {
      const params = this.$route.query;
      if(this.isRequestValid(params)) {
        this.$router.push({ name: 'login', query: params })
      } else {
        this.error = 'Invalid request.'
      }    
    }
  }
}
</script>
